<template>
  <div class="flex h-full items-center">
    <input type="checkbox" :checked="value" :value="value" class="hidden" />
    <span
      class="mr-2 text-sm h-auto"
      :class="!value ? ' text-darkblue-300' : ' text-darkblue-100'"
      >{{ labelOff }}</span
    >
    <div
      class="relative min-w-8 flex items-center cursor-pointer"
      :class="[!value ? '' : 'justify-end']"
      @click="switchIt"
    >
      <div class="line absolute"></div>
      <div
        class="circle absolute"
        :class="[!value ? 'bg-darkblue-400' : 'bg-secondary-500']"
      ></div>
    </div>
    <span
      class="ml-2 text-sm "
      :class="value ? 'text-secondary-500' : 'text-darkblue-300'"
      >{{ labelOn }}</span
    >
  </div>
</template>

<script>
export default {
  name: "q-switch",
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    labelOn: {
      type: String,
      default: ""
    },
    labelOff: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.checked
    };
  },
  methods: {
    switchIt() {
      this.value = !this.value;
      if (!this.readOnly) {
        this.$emit("change", this.value);
      }
      console.log("CAMBIO!!!", this.value, this.checked);
    }
  }
};
</script>

<style lang="postcss">
.circle {
  @apply rounded-full w-6 h-6 shadow-md;
}
.line {
  @apply w-full h-3 rounded-full bg-gray-300 shadow-inner;
}
.on {
  transform: translateX(-50%);
}
.off {
  transform: translateX(50%);
}
</style>
