<template>
  <form @submit.prevent="$emit('sendSms')" class="w-full">
    <h2 class="font-bold mt-8">
      Perché abbiamo bisogno del numero di telefono?
    </h2>
    <p>
      Il numero di cellulare ti servirà per accedere a Remoney, l’app che ti
      permetterà di ricevere i tuoi pagamenti.
    </p>
    <div class="flex my-8 shadow-md items-baseline">
      <div
        class="border border-r-0 border-gray-500 bg-white px-4 py-4 rounded-l-md font-bold"
      >
        +39
      </div>
      <input
        type="tel"
        class="w-full border border-l-0 py-4 border-gray-500 rounded-r-md"
        v-model="s_phonenumber"
      />
    </div>
    <div class="grid grid-cols-2 mt-16">
      <router-link
        :to="`/${$route.params.lang}/legal/privacy`"
        class="col-span-1 font-bold text-gray-700 hover:underline py-2"
      >
        Privacy <i class="mdi mdi-open-in-new"></i>
      </router-link>
      <div class="col-span-1 ">
        <q-switch
          labelOn="Accetto"
          labelOff="Non accetto"
          v-model="privacyCB"
        />
      </div>
      <router-link
        :to="`/${$route.params.lang}/legal/terms`"
        class="col-span-1 font-bold text-gray-700 hover:underline py-2"
      >
        Termini e condizioni <i class="mdi mdi-open-in-new"></i>
      </router-link>
      <div class="col-span-1 ">
        <q-switch labelOn="Accetto" labelOff="Non accetto" v-model="termsCB" />
      </div>
      <router-link
        :to="`/${$route.params.lang}/legal/merchant`"
        class="col-span-1 font-bold text-gray-700 hover:underline py-2"
      >
        Contratto Merchant <i class="mdi mdi-open-in-new"></i>
      </router-link>
      <div class="col-span-1 ">
        <q-switch
          labelOn="Accetto"
          labelOff="Non accetto"
          v-model="merchantCB"
        />
      </div>
    </div>
    <div class="flex items-baseline mt-12">
      <button
        @click="$emit('prevstep')"
        class="text-secondary-500 w-full text-center"
      >
        <i class="mdi mdi-arrow-left"></i>
        Torna indietro
      </button>
      <button
        class="btn btn-cta w-full text-center"
        :class="$v.$invalid ? ' cursor-not-allowed' : null"
        :disabled="$v.$invalid"
      >
        Continua
      </button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, numeric, sameAs, minLength } from "vuelidate/lib/validators";
import { debounce } from "lodash";
import QSwitch from "@/_components/QSwitch";
export default {
  mixins: [validationMixin],
  components: {
    QSwitch
  },
  data() {
    return {
      privacyCB: false,
      termsCB: false,
      merchantCB: false
    };
  },
  computed: {
    ...mapState("createstore", ["phonenumber"]),
    s_phonenumber: {
      get() {
        return this.phonenumber;
      },
      set(v) {
        this.SET_PHONENUMBER(v);
      }
    }
  },
  validations: {
    s_phonenumber: { required, numeric, minLength: minLength(6) },
    privacyCB: { required, sameAs: sameAs(() => true) },
    termsCB: { required, sameAs: sameAs(() => true) },
    merchantCB: { required, sameAs: sameAs(() => true) }
  },
  methods: {
    ...mapMutations("createstore", ["SET_PHONENUMBER"]),
    ...mapActions("createstore", ["loginWithPhone"]),
    submitForm() {
      debounce(function() {
        this.$emit("sendSms");
      }, 1000);
    }
  }
};
</script>

<style>
.shape {
  width: 500px;
  height: 400px;

  animation-name: appear;
  animation-duration: 10s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.shape .fil0 {
  fill: url(#header-shape-gradient) #fff;
}

#header-shape-gradient {
  --color-stop: theme("colors.secondary.300"); /*#f12c06;*/
  --color-bot: theme("colors.secondary.500"); /*#faed34;*/
}

@keyframes appear {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  /* 50% {
    
    opacity: 0.75;
  } */
  10% {
    transform: translateX(0%);
    opacity: 1;
  }
  95% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}
</style>
